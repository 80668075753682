import shared from "./shared";

export const environment = {
  ...shared,
  MINDLAB_FRONTEND_URL: "https://staging.mindlab.be",
  REACT_APP_ENV: "development",
  REACT_APP_MATOMO_KEY: 4,
  REACT_APP_SERVER_URL: "https://api.development.bloomup.org",
  REACT_APP_URL: "https://app.development.bloomup.org",
};

export default environment;
